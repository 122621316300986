﻿import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ChangePassComponent',
    templateUrl: './changepass.component.html'
})

export class ChangePassComponent implements OnInit {
    constructor() { }

    ngOnInit() {

    }
}