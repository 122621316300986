import 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiHost } from '../app.component';
import { AuthService } from './auth.services';
import { SMSRespondents } from '../models/smsrespondents';

@Injectable()
export class SmsServices {
  constructor(private _http: HttpClient, private authService: AuthService) { }

  postSMS(sms) {
    let input = new FormData();
    var json = JSON.stringify(sms);
    input.append("json", json);

    return this._http.post(apiHost + '/api/sms/send', input);
  }

  getSmsReply() {
    return this._http.get(apiHost + '/api/sms/replies');
  }

  getSmsData(entity, id) {
    var sms = new SMSRespondents();
    sms.respondentIds = id;
    let input = new FormData();
    var json = JSON.stringify(id);
    input.append("json", json);
    let respId: string;
    console.log(entity);
    console.log(id);
    if (id.indexOf("?") !== -1) {
      respId = sms.respondentIds.toString().replace("?respondentIds=", "");
      respId = respId.replace(/&respondentIds=/g, ",");
      return this._http.get(apiHost + '/api/sms/' + entity + "?sms=" + respId);
    }
    else {
      if (Array.isArray(id)) {
        respId = id.toString();
        console.log(respId);
        return this._http.get(apiHost + '/api/sms/' + entity + "?sms=" + respId);
      }
      else {
        return this._http.get(apiHost + '/api/sms/' + entity + "?jobGroupId=" + id);
      }
    }

  }

  getResIdsSmsData(resids: Array<string>, sessionid) {
    let input = new FormData();
    var json = JSON.stringify(resids);
    input.append("json", json);

    return this._http.post(apiHost + '/api/sms/CreateSessionConfirmationResIdsSms/' + sessionid, input);
  }

  deleteReplies(deleteItemIds) {
    let input = new FormData();
    var json = JSON.stringify(deleteItemIds);
    input.append("json", json);

    return this._http.post(apiHost + '/api/sms/replies/delete', input);
  }

  getSurveyInviteSmsData(ids, jobid, sessionid) {
    console.log(ids);
    console.log(jobid);
    console.log(sessionid);
    let input = new FormData();
    var json = JSON.stringify(ids);
    input.append("json", json);
    input.append("jobId", jobid);
    if (sessionid == null) sessionid = 0;
    input.append("sessionId", sessionid);

    return this._http.post(apiHost + '/api/sms/CreateRespondentSurveyInviteSms', input);
  }
}
